<template>
  <v-row>
    <v-col cols="12">
      <!-- <v-row>
        
          <v-radio-group v-model="type" @change="onChangeType" row>
            <v-radio label="INV#" :value="false"></v-radio>
            <v-radio label="Date" :value="true"></v-radio>
          </v-radio-group>
      </v-row> -->
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-select
            outlined
            flat
            dense
            :items="listType"
            v-model="params.type"
            @change="onChangeType"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3">
          <v-autocomplete
            placeholder="customer"
            outlined
            flat
            dense
            background-color="white"
            append-icon="mdi-magnify"
            item-text="name"
            item-value="id"
            :items="customers"
            v-model="params.id"
            @change="onChangeCustomer"
            :rules="[v => !!v || 'Customer is required']"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="2" v-if="params.type === 1">
          <v-autocomplete
            placeholder="INV# 1"
            outlined
            flat
            dense
            background-color="white"
            append-icon="mdi-magnify"
            item-value="invoiceNumber"
            item-text="invoiceNumber"
            :items="listInvoice"
            v-model.number="params.invoiceFirst"
            :rules="[v => !!v || 'Invoice First is required']"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="2" v-if="params.type === 1">
          <v-autocomplete
            placeholder="INV# 2"
            outlined
            flat
            dense
            background-color="white"
            append-icon="mdi-magnify"
            item-value="invoiceNumber"
            item-text="invoiceNumber"
            :items="listInvoice"
            v-model.number="params.invoiceSecond"
            :rules="[v => !!v || 'Invoice Second is required']"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="2" v-if="params.type === 2">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.startDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.startDate"
              @input="menuStartDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="2" v-if="params.type === 2">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.endDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.endDate" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6" v-if="params.type === 3">
          <v-autocomplete
            placeholder="Multiple INV#"
            outlined
            flat
            dense
            background-color="white"
            append-icon="mdi-magnify"
            item-value="invoiceNumber"
            item-text="invoiceNumber"
            :items="listInvoice"
            v-model="params.invoices"
            :rules="[v => !!v || 'Invoice is required']"
            multiple
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="1">
          <v-btn color="primary" @click="search">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-if="view">
      <iframe
        :src="url + '#zoom=60'"
        frameborder="0"
        type="application/pdf"
        width="100%"
        height="650px"
      ></iframe>
    </v-col>
  </v-row>
</template>

<script>
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import { mapState } from "vuex";
import moment from "moment";
import base64 from "../../services/base64";

export default {
  name: "tanda-terima",
  data() {
    return {
      params: {
        id: 0,
        invoiceFirst: 0,
        invoiceSecond: 0,
        invoices: [],
        type: 1,
      },
      menuStartDate: false,
      menuEndDate: false,
      listInvoice: [],
      url: "",
      view: false,
      items: [],
      listType: [
        { value: 1, text: "INV#" },
        { value: 2, text: "Date#" },
        { value: 3, text: "Multiple INV#" },
      ],
    };
  },
  computed: {
    ...mapState("external", ["customers"]),
  },
  methods: {
    async search() {
      if (this.params.invoiceFirst > this.params.invoiceSecond) {
        this.$store.dispatch("toast", {
          type: "error",
          message: "INV# 1 tidak boleh lebih besar dari INV# 2",
        });
      } else if (this.params.startDate > this.params.endDate) {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Start Date tidak boleh lebih besar dari End Date",
        });
      } else {
        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("saleInvoice/acceptableInvoice", this.params)
          .then(response => {
            this.items = response.data;
            this.createPdf();
            this.view = true;
            this.$store.commit("SET_LOADER", false);
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    convertToBodyTable(items) {
      const array = [];
      items.forEach((x, index) => {
        array.push([
          index + 1,
          x.invoiceDate,
          x.invoiceNumber,
          x.listDo.split(",").length > 0
            ? x.listDo
                .split(",")
                .slice(0, 1)
                .join(",")
            : x.listDo,
          x.purchaseOrderNumber,
          x.taxInvoiceSerialNumber,
          x.invoiceDate,
          this.formatPrice(x.totalAmountAfterTax),
        ]);
      });
      return array;
    },
    onChangeCustomer(val) {
      this.getDropdown(val);
    },
    onChangeType(val) {
      if (val === 2) {
        this.params = {
          id: 0,
          startDate: moment().format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
          type: this.params.type,
        };
      } else if (val === 1) {
        this.params = {
          id: 0,
          invoiceFirst: 0,
          invoiceSecond: 0,
          type: this.params.type,
        };
      } else {
        this.params = {
          id: 0,
          invoices: [],
          type: this.params.type,
        };
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed().replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async getDropdown(id) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("saleInvoice/getDropdownForTandaTerima", id)
        .then(response => {
          this.listInvoice = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    createPdf() {
      const doc = new jsPDF();
      doc.addImage(base64.logoUrl, "JPEG", 10, 10, 100, 20);

      doc.setFont("Times");
      doc.setFontSize(10);
      doc.text("INTERCON PLAZA BLOK D. 11", 10, 40);
      doc.text("JL. MERUYA ILIR, SRENGSENG-KEMBANGAN", 10, 50);
      doc.text("Phone   (62-21)7587-9949/51", 10, 60);
      doc.text("Fax     (62-21)7587-9952", 10, 70);

      doc.setFont("Times", "bold");
      doc.setFontSize(20);
      doc.text("TANDA TERIMA INVOICE", 60, 85);

      doc.setFont("Times", "normal");
      doc.setFontSize(10);
      doc.text("Dokumen Berupa :", 10, 95);
      doc.text("Invoice / Faktur Pajak / Kwitansi / Surat Jalan Asli", 10, 100);
      doc.text("Diserahkan Kepada :", 140, 95);
      doc.text(this.items.length > 0 ? this.items[0].customerName : "", 140, 100);

      doc.autoTable({
        startY: 110,
        theme: "plain",
        head: [["No", "Date", "Inv#", "DO#", "PO#", "FP", "Tanggal FP", "Amount"]],
        body: this.convertToBodyTable(this.items),
        styles: {
          valign: "middle",
          tableWidth: "auto",
          lineWidth: 0.1,
          halign: "left",
          lineColor: "black",
        },
        headStyles: {
          valign: "middle",
          halign: "center", //'center' or 'right',
          tableWidth: "auto",
        },
      });

      const tableY = doc.lastAutoTable.finalY;
      if (tableY > 270) {
        doc.addPage();
        doc.addImage(base64.logoUrl, "JPEG", 10, 10, 100, 20);

        doc.setFont("Times");
        doc.setFontSize(10);
        doc.text("INTERCON PLAZA BLOK D. 11", 10, 40);
        doc.text("JL. MERUYA ILIR, SRENGSENG-KEMBANGAN", 10, 50);
        doc.text("Phone   (62-21)7587-9949/51", 10, 60);
        doc.text("Fax     (62-21)7587-9952", 10, 70);

        doc.setFont("Times", "bold");
        doc.setFontSize(20);
        doc.text("TANDA TERIMA INVOICE", 60, 85);

        doc.setFont("Times", "normal");
        doc.setFontSize(10);
        doc.text("Dokumen Berupa :", 10, 95);
        doc.text("Invoice / Faktur Pajak / Kwitansi / Surat Jalan Asli", 10, 100);
        doc.text("Diserahkan Kepada :", 140, 95);
        doc.text(this.items.length > 0 ? this.items[0].customerName : "", 140, 100);
        doc.setFont("Times", "bold");
        doc.setFontSize(10);
        doc.text("Diserahkan Oleh", 10, 110);
        doc.text("Diterima Oleh", 140, 110);

        doc.setFont("Times", "normal");
        doc.setFontSize(10);
        doc.line(10, 140, 50, 140);
        doc.line(140, 140, 180, 140);
        doc.text("Nama Jelas & Ttd", 10, 145);
        doc.text("Nama Jelas & Ttd", 140, 145);
      } else {
        doc.setFont("Times", "bold");
        doc.setFontSize(10);
        doc.text("Diserahkan Oleh", 10, 252);
        doc.text("Diterima Oleh", 140, 252);

        doc.setFont("Times", "normal");
        doc.setFontSize(10);
        doc.line(10, 282, 50, 282);
        doc.line(140, 282, 180, 282);
        doc.text("Nama Jelas & Ttd", 10, 287);
        doc.text("Nama Jelas & Ttd", 140, 287);
      }

      this.url = doc.output("datauristring");
    },
    async getCustomer() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("external/getCustomers")
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.getCustomer();
  },
};
</script>

<style></style>
